<script lang="ts" setup>
  import { useAppStore } from '@/stores'
  const appStore = useAppStore()
  const { isMobile, isDesktop } = useBreakpoints()

  const props = defineProps<{
    heroImage: any
    heroCard?: any
    isIndex?: boolean
  }>()

  const getModifiers = computed(() => {
    const data = {}

    if (props.heroImage?.focus) {
      Object.assign(data, { filters: { focal: props.heroImage?.focus } })
    }

    return data
  })

  const getHeight = computed(() => {
    if (props.isIndex) {
      return isMobile.value ? 500 : 712
    }

    return isMobile.value ? 240 : 480
  })

  const getWidth = computed(() => {
    return isMobile.value ? 320 : 1600
  })

  const showAnimation = computed(() => {
    if (!isDesktop.value) return false
    return appStore.indexAnimations.headerNavIsLoaded
  })
</script>

<template>
  <div
    :class="{ 'index-banner': isIndex }"
    class="hero-banner"
  >
    <NuxtImg
      :alt="props.heroImage.alt ?? ''"
      :height="getHeight"
      :modifiers="getModifiers"
      :src="props.heroImage.filename"
      :title="props.heroImage.title ?? ''"
      :width="getWidth"
      format="webp"
      preload
      provider="storyblok"
    />
    <div
      v-if="props.heroCard.length"
      class="hero-banner-events"
    >
      <div
        class="event"
        :class="{ animated: showAnimation }"
      >
        <StoryblokComponent :blok="props.heroCard[0]" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '@/assets/styles/common.scss';

  .hero-banner {
    position: relative;
    margin-bottom: rem(24);

    @include tablet-up {
      margin-bottom: rem(40);
    }

    .event {
      margin: rem(32) rem(16);

      @include tablet-up {
        margin-top: rem(96);
      }

      @include desktop-up {
        position: absolute;
        top: 0;
        bottom: 0;
        left: rem(16);
        right: 0;
        left: 0;
        max-height: rem(420);
        max-width: rem(1120);
        margin: auto;
      }
      
      @include desktop-up-tw {
        opacity: 0;

        &.animated {
          animation-duration: 1s;
          animation-name: slideIn;
          opacity: 1;
        }
      }

      :deep {
        > div {
          background: white;
          margin: auto;

          @include tablet-up {
            margin: 0;
          }
        }
      }
    }

    img {
      height: 100%;
      max-height: rem(240);
      object-fit: cover;
      width: 100%;

      @include tablet-up {
        max-height: rem(480);
      }
    }
  }

  .index-banner {
    position: relative;
    margin-bottom: rem(40);

    @include desktop-up {
      margin-bottom: rem(80);
    }

    img {
      max-height: rem(500);
      height: rem(500);

      @include tablet-up {
        max-height: rem(712);
        height: rem(712);
      }
    }

    .hero-banner-events {
      :deep {
        .event {
          position: absolute;
          top: 0;

          @include tablet-up {
            margin-bottom: rem(80);
          }
        }

        .button-wrapper {
          button {
            width: auto;
          }
        }
      }
    }
  }

  @keyframes slideIn {
    from {
      margin-bottom: rem(-150);
      opacity: 0;
    }

    to {
      margin-bottom: rem(80);
      opacity: 1;
    }
  }
</style>
